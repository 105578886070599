import { getRequest,postRequest,putRequest,deleteRequest} from "@/libs/axios";
// 科目人数限制


export const getSubjectLimit= params =>{
  return getRequest("/yethan/register/subjectLimit/lisAll", params);
}
// 添加科目人数限制
export const addSubjectLimit = params =>{
  return postRequest("/yethan/register/subjectLimit", params);
}
// 修改科目人数限制
export const editSubjectLimit = params => {
  return putRequest("/yethan/register/subjectLimit/", params);
};
// 删除科目人数限制
export function deleteSubjectLimit(sid) {
  return deleteRequest("/yethan/register/subjectLimit/"+sid);
}