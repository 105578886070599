<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  .msgLabel {
    height: 40px;
    line-height: 40px;
  }

  .msgName {
    width: 165px;
    min-width: 165px;
  }

  .msgVal label {
    font-weight: 500;
  }

  .msgVal .form-check {
    line-height: 45px;
  }

  /deep/ .el-form-item__content {
    line-height: inherit;
  }

  /deep/.el-input {
    width: 60%;
    margin-right: 10px;
  }

  /deep/.el-input__inner {
    height: 35px;
    background-color: #FFF9EB;
  }

  /deep/.el-form-item {
    width: 100%;
    margin-bottom: 0;
  }

  /deep/.el-pagination__jump {
    margin-left: 0;
    margin-right: 10px;
  }

  /deep/.el-input.is-disabled .el-input__inner {
    background-color: #FFF9EB;
  }

  .table-Zy td {
    line-height: 20px;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";
  import ExamHeader from "./exam-header";
  import $ from "jquery"
  import {
    getAreaList,
    addAreaList,
    editExamRecord,
    getExamDetails,
    editAreaList,
    deleteAreaList
  } from "@/api/admin/exam/examRecord.js"
  import {
    getDicts
  } from "@/api/common/dict.js";
  import YzSelect from "@/components/form/yzSelect.vue";
  import YzSelectMulit from "@/components/form/yzSelectMulit.vue";
  import {
    createKsbmb
  } from "@/api/admin/exam/createExamku.js"
  import {
    getSubjectLimit,
    addSubjectLimit,
    editSubjectLimit,
    deleteSubjectLimit
  } from "@/api/admin/exam/subjectLimit.js"
  import {
    classificationInfoList
  } from "@/api/admin/base/classification.js"
  /**
   * 考试报名--审核流程

   */
  export default {
    components: {
      Layout,
      CheckHeader,
      ExamHeader,
      YzSelectMulit,

    },
    data() {
      return {
        title: "考试报名信息管理/ ",
        title2: "",
        items: [{
            text: "用户首页",
            href: '/admin'
          },
          {
            text: "考试流程",
            active: true
          }
        ],
        dict: {},
        form: {
          ksnf: "",
          zdksrs: "",
          ybmrs: "",
        },
        subjectForm: {},
        showmodal: false,
        showKm: false,
        addKmList: false,
        xgfw: [],
        subjectArry: [],
        sfzjlxArry: [],
        classificationlist: [],
        tabSubList: [],
        area: {
          zdksrs: 0,
          ksnf: "",
          ssdm: "51",
          djsdm: "00",
          qxdm: "000000",
        },
        sid: "",
        checkForm: [],
        ksbmbh: "",
        ksbmb: "",
        dszmc: "",
        dszdm: "",
        areaList: [],
        subject: {
          zdksrs: 0,
        },
        ksmc: "",
        subjectList: [],
        kszrxzlx: "1",
        checkSubject: [],
        examForm: {
          sfxywsbm: "",
          bmkbm: "",
          sfxyzgjgsh: "",
          zgjgshshms: "",
          zpsfxysh: "",
          zpshms: "",
          sfyxsd: "",
          sdsjxz: "",
          jfqsfyxxgxx: "",
          yxxgfw: "",
          jfhsfqrbm: "",
          bmqrms: "",
          dyzkzsfqr: "",
          dyzkzqrms: "",
          qyzt: 1,

        },
        addExamLocalForm: {}
      };

    },
    methods: {
      editExamForm() {
        if (this.examForm.zgjgshshms) {
          let formDate = this.examForm
          formDate.yxxgfw = [0, 0, 0, 0]
          this.xgfw.forEach((item) => {
            formDate.yxxgfw[item - 1] = 1
          })
          formDate.yxxgfw = formDate.yxxgfw.join('')
          formDate.yxbmzjlx = this.checkForm.toString()
          editExamRecord({
            ...formDate,
            sid: this.sid
          }).then(res => {
            if (res.status) {
              this.success()
            }
          })
        } else {
          this.$message({
            type: 'warning',
            message: '请先选择审核模式',
          })
        }

      },
      success() {
        this.$notify({
          type: 'success',
          message: '数据操作成功!',
          duration: 3000,
        })
        this.$refs.header.getUrl('/admin/examTime')

      },
      getDetail(ksbmbh) {
        getExamDetails(ksbmbh).then(res => {
          if (res.status) {
            this.examForm = res.data
            this.ksbmbh = this.examForm.ksbmbh
            let formDate = this.examForm
            this.ksbmb = this.$route.query.ksbmb
            this.area.ksnf = this.examForm.ksnf
            this.examForm.bmkbm = this.ksbmb ? this.ksbmb : this.examForm.bmkbm
            this.ksmc = res.data.ksmc ? res.data.ksmc : ""
            if (this.examForm.bmkbm) {
              $("#bmkb").attr("disabled", "disabled")
            }
            if (formDate.yxbmzjlx) {
              this.checkForm = formDate.yxbmzjlx.split(",")
            } else {
              this.checkForm = []
            }
            if (formDate.yxxgfw) {
              this.xgfw = formDate.yxxgfw.split('')
              this.xgfw.forEach((item, index) => {
                if (item != 0) {
                  this.xgfw[index] = index + 1
                }
              })
            }
            if (this.examForm.kmxzfs == 1) {
              // this.getClassification(this.examForm.flbm, this.examForm.lbbm)
              this.getKmList()
            }
            this.getList()
          }
        })
      },
      getList() {
        getAreaList({
          ksbmbh: this.ksbmbh
        }).then(res => {
          this.areaList = res.data
        })
      },
      isLike(form1, form2) {
        let count = 0
        if (form1.ssdm == form2.ssdm) {
          count++;
        }
        if (form1.dszdm == form2.djsdm) {
          count++;
        }
        if (form2.qxdm.substring(form2.qxdm.length - 2) == '00') {
          form2.qxdm = ""
          form2.qxmc = ""
        }
        if (form1.qxdm) {
          return form1.qxdm == form2.qxdm
        } else if (form2.qxdm) {
          return false
        } else {
          return count >= 2
        }
      },
      // 添加考区
      addArea() {
        if (this.areaList.length == 0 || !this.areaList.filter(k => {
            return this.isLike(k, this.area)
          })[0]) {
          this.addAreaPort()
        } else {
          this.$message({
            type: "warning",
            message: "当前考试已包含相同地区，不可重复添加哦",
          });
        }
      },
      test() {
        console.log(this.checkForm.toString())
        console.log(this.checkForm.toString().split(","));
      },
      // 添加考区接口
      addAreaPort() {
        let form = {}
        form.ssdm = this.area.ssdm
        form.ssmc = this.area.ssmc
        form.dszdm = this.area.djsdm
        form.dszmc = this.area.djsmc
        if (this.area.qxdm.substring(this.area.qxdm.length - 2) == '00') {
          form.qxdm = ""
          form.qxmc = ""
        } else if (this.area.qxdm == "") {
          form.qxdm = ""
          form.qxmc = ""
        } else {
          form.qxdm = this.area.qxdm
          form.qxmc = this.area.qxmc
        }
        form.zdksrs = !this.area.zdksrs ? 0 : this.area.zdksrs
        form.qyzt = 1
        addAreaList({
          ...form,
          ksbmbh: this.ksbmbh
        }).then((res) => {
          if (res.status) {
            this.$message({
              type: "success",
              message: "新增成功!",
            });
            this.area.ssdm = "51"
            this.area.djsdm = "00"
            this.area.qxdm = "000000"
            this.$refs.localSelect.reload()
          }
          this.getList();
        });
      },

      // 删除考区
      deleteArea(sid) {
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteAreaList(sid).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据删除成功!'
              });
            }
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      // 编辑考区
      editArea(data) {
        let copyData = JSON.parse(JSON.stringify(data))
        this.form = copyData
        this.form.djsdm = this.form.dszdm
        this.form.djsmc = this.form.dszmc
        this.showmodal = true
      },
      // 修改考区
      submitArea(k) {
        if (this.areaList.length) {
          if (this.areaList.some(v => {
              return this.isLike(v, k)
            })) {
            this.$message({
              type: "warning",
              message: "当前考试已包含相同地区，不可重复添加哦",
            });
            return
          } else {
            editAreaList(k).then(res => {
              if (res.status) {
                this.$message({
                  type: 'success',
                  message: '数据修改成功'
                });
              }
              this.showmodal = false
              this.getList()
            })
          }
        }

      },

      // 编辑最大考试人数
      editZdksrs(val) {
        editAreaList({
          zdksrs: val.zdksrs,
          sid: val.sid,
          ksbmbh: this.ksbmbh
        }).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: '最大考试人数修改成功！'
            });
          }
        })
      },


      // 选科最大人数列表
      getKmList() {
        getSubjectLimit({
          ksbmbh: this.ksbmbh
        }).then(res => {
          this.subjectList = res.data.list
          this.tabSubList = res.data.subjectList
          this.subjectList.forEach(k => {
            k.children = this.tabSubList.filter(m => {
              return m.xkdm == k.xkdm
            })

          })
        })
      },
      getxkmc() {
        let val = this.subjectForm.xkdm
        let obj = this.classificationlist.find(k => {
          return k.xkfm === val
        })
        this.subjectForm.xkmc = obj.xkmc

      },
      //获取选科
      // getClassification(p1, p2) {
      //   classificationInfoList({
      //     flbm: p1,
      //     lbbm: p2,
      //   }).then(res => {
      //     if (res.status) {
      //       this.classificationlist = res.data.classificationlist
      //       this.subjectArry = res.data.subjectList
      //       this.classificationlist.forEach(k => {
      //         k.children = this.subjectArry.filter(m => {
      //           return m.xkdm == k.xkdm
      //         })

      //       })
      //     }
      //   })
      // },
      // 添加科目
      // addKm() {
      //   let box = this.checkSubject
      //   let newArr = []
      //   let hasSelect = {}
      //   this.subjectList.forEach(k => {
      //     hasSelect[k.xkdm] = 1
      //   })

      //   let flag = 0
      //   box.forEach(k => {
      //     if (hasSelect[k.xkdm]) {
      //       flag = 1
      //       return
      //     } else {
      //       newArr.push({
      //         ksbmbh: this.ksbmbh,
      //         zdksrs: k.zdksrs,
      //         xkdm: k.xkdm,
      //         xkmc: k.xkmc,
      //       })
      //     }
      //   })

      //   if (!newArr.length && flag) {
      //     this.$message({
      //       type: "warning",
      //       message: "当前考试已包含相同科目，不可重复添加",
      //     });
      //     return
      //   }

      //   addSubjectLimit(
      //     newArr
      //   ).then((res) => {
      //     if (res.status) {
      //       this.$message({
      //         type: "success",
      //         message: "新增成功!",
      //       });
      //       this.addKmList = false
      //     }
      //     this.getKmList();
      //   });

      // },
      // 删除科目
      // deleteKm(sid) {
      //   this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }).then(() => {
      //     deleteSubjectLimit(sid).then(res => {
      //       if (res.status) {
      //         this.$message({
      //           type: 'success',
      //           message: '数据删除成功!'
      //         });
      //       }
      //       this.getKmList();
      //     })
      //   }).catch(() => {
      //     this.$message({
      //       type: 'info',
      //       message: '已取消删除'
      //     });
      //   });
      // },

      // // 编辑科目
      // editKm(data) {
      //   let copyData = JSON.parse(JSON.stringify(data))
      //   this.subjectForm = copyData
      //   this.subjectForm.xkmc = copyData.xkmc
      //   this.showKm = true
      // },

      // // 修改选科
      // submitKm(k) {
      //   editSubjectLimit(k).then(res => {
      //     if (res.status) {
      //       this.$message({
      //         type: 'success',
      //         message: '数据修改成功'
      //       });
      //     }
      //     this.showKm = false
      //     this.getKmList();
      //   })
      // },
      // 编辑最大考试人数
      editSubZdksrs(val) {
        editSubjectLimit({
          zdksrs: val.zdksrs,
          sid: val.sid,
        }).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: '最大考试人数修改成功！'
            });
          }
        })
      },
      // 生成考试报名库
      addKsbmk() {
        this.$confirm('此操作将生成报名库,请确认是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            createKsbmb({
              ksbmbh: this.ksbmbh
            }).then(res => {
              if (res.status) {
                this.examForm.bmkbm = res.message
              }
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消生成报名库'
            });
          });
      },
      // 编辑考区启用状态
      editAreaZt(sid, qyzt) {
        let formData = {}
        formData.sid = sid
        formData.qyzt = qyzt
        formData.ksbmbh = this.ksbmbh
        editAreaList(formData).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: res.message ? res.message : '修改成功！',
            });
          }
        })
      },
      // 编辑专业启用状态
      editSubQyzt(sid, qyzt) {
        let formData = {}
        formData.sid = sid
        formData.qyzt = qyzt
        editSubjectLimit(formData).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: res.message ? res.message : '修改成功！',
            });
          }
        })
      },
    },
    mounted() {
      if (this.ksbmbh) {
        this.getDetail(this.ksbmbh);
      }
      getDicts("dict_xzqh", "dict_sfzjlx").then((res) => {

        this.dict = res;
        this.sfzjlxArry = res.dict_sfzjlx
        if (!this.checkForm.length) {
          this.checkForm = []
          res.dict_sfzjlx.forEach(k => {
            this.checkForm.push(k.details.sfzjlxm)
          })
        }

      });

    },
    created() {
      this.sid = this.$route.query["sid"] ? this.$route.query["sid"] : "";
      this.ksbmbh = this.$route.query["ksbmbh"] ? this.$route.query["ksbmbh"] : "";
    }
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="ksmc" :items="items" />
    <ExamHeader :ksbmbh="ksbmbh" ref="header" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 470px;">
          <div class="card-body">
            <form action="" method="" ref="examForm">
              <div class="outerCotent mt-3 ">
                <input type="hidden" class="  w-30" value="" name="sid">
                <div class="msgContent flexList fs-xs">
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">是否需要网上报名 </div>
                    <div class="msgVal flexList">
                      <b-form-checkbox v-model="examForm.sfxywsbm" switch class="mt-3 switch-check">
                      </b-form-checkbox>
                      <span v-if="examForm.sfxywsbm==1" class="blue-font"> 开启 </span>
                      <span v-else class="red-font"> 关闭 </span>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">报名库命名</div>
                    <div class="msgVal  flexList">
                      <input type="text" name="" v-model="examForm.bmkbm" disabled id="bmkb" value=""
                        class="h30 w-50 form-control" style="cursor: no-drop;" />
                      <!-- <button type="button"  class="btn btn-info h30 ml-2"
                        @click="addKsbmk">生成报名库</button> -->
                      <i class="ml-2 font-size-12" v-if="!examForm.bmkbm">在最后一步自动生成，不需要修改</i>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">是否需要主管机构审核 </div>
                    <div class="msgVal flexList">
                      <b-form-checkbox v-model="examForm.sfxyzgjgsh" switch class="mt-3 switch-check">
                      </b-form-checkbox>
                      <span v-if="examForm.sfxyzgjgsh==1" class="blue-font"> 开启 </span>
                      <span v-else class="red-font"> 关闭 </span>

                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">审核模式(必填) </div>
                    <div class="msgVal flexList">
                      <div class="frame-wrap">
                        <div class="custom-control custom-radio custom-control-inline">
                          <input type="radio" v-model="examForm.zgjgshshms" class="custom-control-input"
                            name="zgjgshshms" value="1" id="zgjgshshms1">
                          <label class="custom-control-label" style="line-height:19px" for="zgjgshshms1">报考单位审核</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                          <input type="radio" v-model="examForm.zgjgshshms" class="custom-control-input "
                            name="zgjgshshms" value="2" id="zgjgshshms2">
                          <label class="custom-control-label" style="line-height:19px" for="zgjgshshms2">区域+专业审核</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                          <input type="radio" v-model="examForm.zgjgshshms" class="custom-control-input "
                            name="zgjgshshms" value="3" id="zgjgshshms3">
                          <label class="custom-control-label" style="line-height:19px"
                            for="zgjgshshms3">工作任务划分审核</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">照片是否需要审核 </div>
                    <div class="msgVal flexList">
                      <b-form-checkbox v-model="examForm.zpsfxysh" switch class="mt-3 switch-check">
                      </b-form-checkbox>
                      <span v-if="examForm.zpsfxysh==1" class="blue-font"> 开启 </span>
                      <span v-else class="red-font"> 关闭 </span>

                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">审核模式 </div>
                    <div class="msgVal flexList">
                      <div class="frame-wrap">
                        <div class="custom-control custom-radio custom-control-inline">
                          <input type="radio" class="custom-control-input" v-model="examForm.zpshms" name="zpshms"
                            value="0" id="zpshms1">
                          <label class="custom-control-label" style="line-height:19px"
                            for="zpshms1">不审核，只能使用已认证照片</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                          <input type="radio" class="custom-control-input " v-model="examForm.zpshms" name="zpshms"
                            value="1" id="zpshms2">
                          <label class="custom-control-label" style="line-height:19px" for="zpshms2">机器审核</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                          <input type="radio" class="custom-control-input " v-model="examForm.zpshms" name="zpshms"
                            value="2" id="zpshms3">
                          <label class="custom-control-label" style="line-height:19px" for="zpshms3">主管机构审核</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">是否允许锁定 </div>
                    <div class="msgVal flexList">
                      <b-form-checkbox v-model="examForm.sfyxsd" switch class="mt-3 switch-check">
                      </b-form-checkbox>
                      <span v-if="examForm.sfyxsd==1" class="blue-font"> 开启 </span>
                      <span v-else class="red-font"> 关闭 </span>

                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">锁定时间限制 </div>
                    <div class="msgVal  flexList">
                      <select class="form-control w-50 form-select" v-model="examForm.sdsjxz">
                        <option value="30">30分钟 </option>
                        <option value="60">1小时</option>
                        <option value="120">2小时</option>
                        <option value="180">3小时</option>
                        <option value="240">4小时</option>
                      </select>
                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">缴费前是否允许修改信息 </div>
                    <div class="msgVal flexList">
                      <b-form-checkbox v-model="examForm.jfqsfyxxgxx" switch class="mt-3 switch-check">
                      </b-form-checkbox>
                      <span v-if="examForm.jfqsfyxxgxx" class="blue-font"> 开启 </span>
                      <span v-else class="red-font"> 关闭 </span>

                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">允许修改范围 </div>
                    <div class="msgVal pr-3">
                      <div class="flexList ">
                        <div class="form-check mr-2"><input type="checkbox" value="1" name="yxxgxxfw" v-model="xgfw"
                            class="form-check-input mt-3"><label for="defaultCheck1" class="form-check-label">报名职位/科目

                          </label></div>
                        <div class="form-check mr-2"><input type="checkbox" value="2" v-model="xgfw" name="yxxgxxfw"
                            class="form-check-input mt-3"><label for="defaultCheck2"
                            class="form-check-label">基本信息</label>
                        </div>
                        <div class="form-check mr-2"><input type="checkbox" value="3" v-model="xgfw" name="yxxgxxfw"
                            class="form-check-input mt-3"><label for="defaultCheck3" class="form-check-label">照片</label>
                        </div>
                        <div class="form-check "><input type="checkbox" value="4" v-model="xgfw" name="yxxgxxfw"
                            class="form-check-input mt-3"><label for="defaultCheck2" class="form-check-label">
                            学习工作经历/家庭成员</label>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">缴费后是否确认报名 </div>
                    <div class="msgVal flexList">
                      <b-form-checkbox v-model="examForm.jfhsfqrbm" switch class="mt-3 switch-check">
                      </b-form-checkbox>
                      <span v-if="examForm.jfhsfqrbm" class="blue-font"> 开启 </span>
                      <span v-else class="red-font"> 关闭 </span>

                    </div>
                  </div>
                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">确认模式 </div>
                    <div class="msgVal flexList">
                      <div class="frame-wrap">
                        <div class="custom-control custom-radio custom-control-inline">
                          <input type="radio" class="custom-control-input" name="bmqrms" id="bmqrms1"
                            v-model="examForm.bmqrms" value=false>
                          <label class="custom-control-label" style="line-height:20px" for="bmqrms1">手动确认</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                          <input type="radio" class="custom-control-input " name="bmqrms" id="bmqrms2"
                            v-model="examForm.bmqrms" value=true>
                          <label class="custom-control-label" style="line-height:20px" for="bmqrms2">不需要确认</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">打印准考证是否需要确认 </div>
                    <div class="msgVal flexList">
                      <b-form-checkbox v-model="examForm.dyzkzsfqr" switch class="mt-3 switch-check">
                      </b-form-checkbox>
                      <span v-if="examForm.dyzkzsfqr" class="blue-font"> 开启 </span>
                      <span v-else class="red-font"> 关闭 </span>

                    </div>
                  </div>

                  <div class="flexList w-50 msgLabel">
                    <div class="msgName">确认模式 </div>
                    <div class="msgVal flexList">
                      <div class="frame-wrap">
                        <div class="custom-control custom-radio custom-control-inline">
                          <input type="radio" class="custom-control-input" v-model="examForm.dyzkzqrms" name="qyms"
                            id="qyms1" value=false>
                          <label class="custom-control-label" style="line-height:20px" for="qyms1">系统判断自动标记</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                          <input type="radio" class="custom-control-input " v-model="examForm.dyzkzqrms" name="qyms"
                            id="qyms2" value=true>
                          <label class="custom-control-label" style="line-height:20px" for="qyms2">用户手动确认</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flexList w-100 msgLabel" style="height:95px;">
                    <div class="msgName">允许报名证件类型 </div>
                    <div class="msgVal flexList">
                      <div v-if="sfzjlxArry" style="white-space:break-spaces ;line-height: 30px;" class="flexList">
                        <el-checkbox-group v-model="checkForm" @change="test">
                          <el-checkbox v-for="(item,index) in sfzjlxArry" :key="index" :value="item.details.sfzjlxm"
                            :label="item.details.sfzjlxm">
                            {{item.details.sfzjlxmc}}
                          </el-checkbox>
                        </el-checkbox-group>
                      </div>
                    </div>
                  </div>
                  <div class="flexList w-100 msgLabel msgtextarea">
                    <div class="msgName flexList justify-content-center">流程预览</div>
                    <div class="msgVal flexList ">
                      <div class="col-xl-8">
                        <div class="d-flex  pt-2">
                          <div class="flexList  w-20" v-show="examForm.sfxywsbm">
                            <div class="exam-progress">
                              考生报名 </div>
                            <div class="exam-progress-arr">
                              <i class=" m-2 iconfont icon-angle-double-right"></i>
                            </div>
                          </div>
                          <div class="flexList  w-20" v-show="examForm.sfxyzgjgsh">
                            <div class="exam-progress">
                              报到单位审核
                            </div>
                            <div class="exam-progress-arr">
                              <i class=" m-2 iconfont icon-angle-double-right"></i>
                            </div>
                          </div>
                          <div class="flexList  w-20" v-show="examForm.jfqsfyxxgxx">
                            <div class="exam-progress">
                              报名线上确认
                            </div>
                            <div class="exam-progress-arr">
                              <i class=" m-2 iconfont icon-angle-double-right"></i>
                            </div>
                          </div>
                          <div class="flexList  w-20" v-show="examForm.jfhsfqrbm">
                            <div class="exam-progress">
                              考生缴费
                            </div>
                            <div class="exam-progress-arr">
                              <i class=" m-2 iconfont icon-angle-double-right"></i>
                            </div>
                          </div>

                          <div class="flexList  w-20" v-show="examForm.dyzkzsfqr">
                            <div class="exam-progress">
                              打印准考证
                            </div>
                            <div class="exam-progress-arr">
                              <i class=" m-2 iconfont icon-angle-double-right"></i>
                            </div>
                          </div>
                          <div class="flexList  w-20">
                            <div class="exam-progress">
                              考试、成绩查询
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-100">
                    <div class="flexList w-100 msgLabel" style="height: 300px;line-height: unset;" v-if="kszrxzlx==1">
                      <div class="msgName flexList justify-content-center">考区及人数设置</div>
                      <div class="msgVal flexList">
                        <div class="col-sm-12 h-100 ">
                          <div class="flexList mt-2">
                            <yz-select-mulit class=" flexList" style="width: 35%;" v-model="area" ref="localSelect"
                              :dict="dict.dict_xzqh" :bounds="[
                                ['ssdm', 'ssmc'],
                                ['djsdm', 'djsmc'],
                                ['qxdm', 'qxmc'],
                              ]"></yz-select-mulit>

                            <input placeholder="最大考试人数" class="h30 form-control mr-2 w-15" maxlength="50"
                              v-model="area.zdksrs" />
                            <b-button variant="outline-info" class="flexList mr-2  h30" @click="addArea"><i
                                class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>添加考区
                            </b-button>
                            <span class="text-danger font-size-12">（说明：请设置考区，人数0表示不限制）</span>
                          </div>
                          <div style="overflow-y: auto;" class="h-90">
                            <table class="table table-bordered table-hover mt-2">
                              <thead class="">
                                <tr>
                                  <th style="width:4%;">序号</th>
                                  <th>省市</th>
                                  <th>地市州</th>
                                  <th>区县</th>
                                  <th style="width: 5%;">排序值</th>
                                  <th style="width: 8%;">最大考试人数</th>
                                  <th style="width: 8%;">已报名人数</th>
                                  <th style="width: 6%;">是否启用</th>
                                  <th style="width:10%;" class="text-center">操作</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(obj,index) in areaList" :key="index">
                                  <td>{{index+1}}</td>
                                  <td>{{obj.ssmc}}</td>
                                  <td>{{obj.dszmc}}</td>
                                  <td>{{obj.qxmc}}</td>
                                  <td>{{obj.pxh}}</td>
                                  <td><input placeholder="最大考试人数" class="h30 form-control " maxlength="50"
                                      v-model="obj.zdksrs" @change="editZdksrs(obj)" /></td>
                                  <td>{{obj.ybmrs}}</td>
                                  <td class="text-center">
                                    <b-form-checkbox v-model="obj.qyzt" switch class="mt-1 switch-check" :value="1"
                                      :unchecked-value="0" @change="editAreaZt(obj.sid,obj.qyzt)">
                                    </b-form-checkbox>
                                  </td>
                                  <td class="tab-icon"><i class="iconfont icon-edit-two align-middle mr-1"
                                      @click="editArea(obj)"></i><i
                                      class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                                      @click="deleteArea(obj.sid)"></i></td>
                                </tr>

                              </tbody>
                            </table>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="flexList w-100 msgLabel" style="height: 300px;line-height: unset;"
                      v-show="examForm.kmxzfs==1">
                      <div class="msgName flexList justify-content-center">专业人数设置 </div>
                      <div class="msgVal flexList">
                        <div class="col-sm-12 h-100 ">
                          <div class="flexList mt-2">
                            <!-- <b-button variant="outline-info" class="flexList mr-2  h30" @click="addKmList=true"><i
                                class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>添加专业
                            </b-button> -->
                            <span class="text-danger font-size-12">（说明：专业人数0表示不限制）</span>
                          </div>
                          <div style="overflow-y: auto;" class="h-90">
                            <table class="table table-bordered table-hover mt-2 table-Zy">
                              <thead class="">
                                <tr>
                                  <th style="width:4%;">序号</th>
                                  <th style="width:8%">专业代码</th>
                                  <th>专业名称</th>
                                  <th>级别</th>
                                  <th>考试科目</th>
                                  <th style="width: 6%;">是否启用</th>
                                  <th style="width: 8%;">最大考试人数</th>
                                  <th style="width: 8%;">已报名人数</th>
                                  <!-- <th style="width:6%;" class="text-center">操作</th> -->
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(obj,index) in subjectList" :key="index">
                                  <td>{{index+1}}</td>
                                  <td>{{obj.xkdm}}</td>
                                  <td>{{obj.xkmc}}</td>
                                  <th>{{obj.jbmc}}</th>
                                  <td>
                                    <div v-for="(item,i) in obj.children" :key="i">{{item.kmmc}} </div>
                                  </td>
                                  <td class="text-center">
                                    <b-form-checkbox v-model="obj.qyzt" switch class="mt-1 switch-check" :value="1"
                                      :unchecked-value="0" @change="editSubQyzt(obj.sid,obj.qyzt)">
                                    </b-form-checkbox>
                                  </td>
                                  <td><input placeholder="0" class="h30 form-control " maxlength="50"
                                      v-model="obj.zdksrs" @change="editSubZdksrs(obj)" /></td>
                                  <td>{{obj.ybmrs}}</td>
                                  <!-- <td class="tab-icon"><i class="iconfont icon-edit-two align-middle mr-1"
                                      @click="editKm(obj)"></i><i
                                      class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                                      @click="deleteKm(obj.sid)"></i></td> -->
                                </tr>

                              </tbody>
                            </table>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="flexList w-100 msgLabel" style="height:60px ;">
                    <div class="msgName "></div>
                    <div class="msgVal flexList">
                      <button type="button" class="btn btn-info h35 mr-2 sub-btn"
                        @click="editExamForm">保存信息，进入下一步</button>
                    </div>
                  </div>
                </div>


              </div>
            </form>

          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->
    <b-modal id="editArea" v-model="showmodal" centered title="修改考区" title-class="font-18" hide-footer>
      <el-form ref="form" v-model="form" label-width="100px">
        <el-form-item label="考试年份">
          <el-input v-model="form.ksnf" disabled></el-input>
        </el-form-item>
        <el-form-item label="考区选择">
          <yz-select-mulit class=" flexList" v-model="form" :dict="dict.dict_xzqh" :bounds="[
              ['ssdm', 'ssmc'],
              ['dszdm', 'dszmc'],
              ['qxdm', 'qxmc'],
            ]"></yz-select-mulit>
        </el-form-item>
        <el-form-item label="最大考试人数">
          <el-input v-model="form.zdksrs"></el-input>
        </el-form-item>
        <el-form-item label="已报名人数">
          <el-input v-model="form.ybmrs" disabled></el-input>
        </el-form-item>
      </el-form>
      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
          @click="submitArea(form)">确定</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal= false">取消</button>
      </div>
    </b-modal>
    <!-- 科目人数弹窗 -->
    <!-- 添加 -->
    <b-modal id="editKm" v-model="addKmList" centered title="添加专业" size="lg" title-class="font-18" hide-footer>
      <div class="flexList">
        <a href="/admin/baseMajors"><button type="button" class="btn btn-info h30 w-md mr-3 flexList"><i
              class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>添加专业</button></a>
        <span>如果没有可选专业,请先在基础数据库中添加。</span>
      </div>
      <table class="table table-bordered table-hover mt-2">
        <thead class="">
          <tr>
            <th style="width: 5%;"> 选择</th>
            <th>专业代码</th>
            <th>专业名称</th>
            <th>考试科目</th>
            <th style="width: 25%;">最大报名人数（0表示不限）</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(obj,index) in classificationlist" :key="index">
            <td><input type="checkbox" :value="obj" v-model="checkSubject"></td>
            <td>{{obj.xkdm}}</td>
            <td>{{obj.xkmc}}</td>
            <td>
              <div v-for="(item,i) in obj.children" :key="i">{{item.kmmc}}</div>
            </td>
            <td><input placeholder="最大考试人数" class="h30 form-control " maxlength="50" v-model="obj.zdksrs" />
            </td>
          </tr>

        </tbody>
      </table>
      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
          @click="addKm()">确定添加</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="addKmList=false">取消</button>
      </div>
    </b-modal>
    <!-- 修改 -->
    <b-modal id="editKm" v-model="showKm" centered title="修改专业" title-class="font-18" hide-footer>
      <el-form ref="subjectForm" v-model="subjectForm" label-width="100px">
        <el-form-item label="考试年份">
          <el-input v-model="subjectForm.ksnf" disabled></el-input>
        </el-form-item>
        <el-form-item label="专业名称">
          <select name="" class="form-control form-select" v-model="subjectForm.xkmc" @change="getxkmc"
            style="width: 60%;">
            <option value="" disabled>专业名称</option>
            <option value="无">无</option>
            <option :value="item.xkdm" v-for="item in classificationlist" :key="item.value">{{item.xkmc}}
            </option>
          </select>
        </el-form-item>
        <el-form-item label="最大考试人数">
          <el-input v-model="subjectForm.zdksrs"></el-input>
        </el-form-item>
        <el-form-item label="已报名人数">
          <el-input v-model="subjectForm.ybmrs" disabled></el-input>
        </el-form-item>
      </el-form>
      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
          @click="submitKm(subjectForm)">确定</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showKm=false">取消</button>
      </div>
    </b-modal>
    <!-- 弹窗结束 -->
  </Layout>
</template>
